import React, { useEffect, useState } from 'react';
import { Grid, GridColumn } from '@atlaskit/page';
import { Label } from '@atlaskit/field-base';
import Spinner from '@atlaskit/spinner';
import BackendPage from '../../../layout/BackendPage';
import { FlexContainer, PageTitle } from '../../../shared/styles/styles';
import Jobs from '../../../shared/job/jobs/Jobs';
import MultiSelect from '../../../shared/form/MultiSelect';
import ResetBtn from '../../../shared/buttons/ResetBtn';
import ContactCompanyService from '../../../services/ContactCompanyService';
import { iLabelValuePair } from '../../../shared/UITypes/types';
import { getJobCategories, getJobs } from '../../../services/JobService';
import { CATEGORY_CODE_NEW, CATEGORY_CODE_WIP } from '../../../types/status/iEntityCategory';
import { apiErrorToast } from '../../../shared/toast/Toast';
import iJobCategory from '../../../types/job/iJobCategory';
import ExportReport from '../../../shared/exportReport/ExportReport';
import iJob from '../../../types/job/iJob';
import { formatDate, getExportReportName, handleNullException } from '../../../services/UtilsService';
import { iConfigColumn } from '../../despatchNote/shared/DispatchNote.type';

const ScheduledCustomerJobs = () => {
  const columns = {
    name: 'scheduledCustomerJobs',
    default: ['Job', 'Customer Ref', 'Product Name', 'Scheduled Date', 'Qty Total'],
    mustBefore: ['Job'],
    mustAfter: [],
    positionFixed: [],
  };
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<iLabelValuePair[]>([]);
  const [openJobStatusIds, setOpenJobStatusIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataTotal, setDataTotal] = useState(0);

  useEffect(() => {
    let isCanceled = false;

    setIsLoading(true);
    getJobCategories()
      .then(resp => {
        if (isCanceled) {
          return;
        }
        const openJStatusIds = (resp || [])
          .filter((cat: iJobCategory) => cat.name === CATEGORY_CODE_NEW || cat.name === CATEGORY_CODE_WIP)
          .map((cat: iJobCategory) => (cat.jobStatuses || []).map(status => status.id))
          .flat();
        setOpenJobStatusIds(openJStatusIds);
      })
      .catch(err => {
        if (isCanceled) {
          return;
        }
        apiErrorToast(err);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
  }, []);

  const getFilterConf = () => {
    return {
      filter: `statusId:${openJobStatusIds.join('|')},SalesOrder_salesOrderId.customerId:${selectedCustomerIds
        .map(ids => ids.value)
        .join('|')}`,
      sort: 'scheduledAt:DESC',
    };
  };

  useEffect(() => {
    if (selectedCustomerIds.length <= 0 || openJobStatusIds.length <= 0) {
      return undefined;
    }

    let isCanceled = false;
    setIsLoading(true);
    getJobs(1, 1, getFilterConf())
      .then(resp => {
        if (isCanceled) {
          return;
        }
        setDataTotal(resp.total || 0);
      })
      .finally(() => {
        if (isCanceled) {
          return;
        }
        setIsLoading(false);
      });
    return () => {
      isCanceled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openJobStatusIds, selectedCustomerIds]);

  const onReset = () => {
    setSelectedCustomerIds([]);
    setIsLoading(false);
    setDataTotal(0);
  };

  const getReportArrSegments = (jobs: iJob[], selColumns: iConfigColumn[]) => {
    const selectedColumnNames = selColumns
      .map((column: iConfigColumn) => column.name)
      .filter((columnName: string) => columnName !== '');

    const reportCells = jobs.map((item: iJob) => {
      return selColumns.map((column: iConfigColumn) => {
        const { key, type, dataSource } = column;
        switch (type) {
          case 'text':
            return handleNullException(item, key);
          case 'date':
            return formatDate(handleNullException(item, key), dataSource[0]);
          case 'qty': {
            return handleNullException(item, key);
          }
          default:
            return '';
        }
      });
    });

    return [selectedColumnNames, ...reportCells];
  };

  const getSearchFn = (pageNo = 1) => {
    return getJobs(pageNo, 10, getFilterConf());
  };
  const getExportBtn = () => {
    if (selectedCustomerIds.length <= 0 || openJobStatusIds.length <= 0 || dataTotal <= 0) {
      return null;
    }
    return (
      <ExportReport
        perPage={10}
        total={dataTotal}
        getFn={(currentPage: number) => getSearchFn(currentPage)}
        onFormatReport={(jobs: iJob[]) =>
          getReportArrSegments(jobs, [
            {
              type: 'text',
              key: 'jobNumber',
              name: 'Job',
              dataSource: [],
            },
            {
              type: 'text',
              key: 'salesOrder.customerRef',
              name: 'Customer Ref',
              dataSource: [],
            },
            {
              type: 'text',
              key: 'product.name',
              name: 'Product',
              dataSource: [],
            },
            {
              type: 'date',
              key: 'scheduledAt',
              name: 'Scheduled Date',
              dataSource: ['DD/MMM/YYYY'],
            },
            {
              type: 'text',
              key: 'qtyTotal',
              name: 'Qty Total',
              dataSource: [],
            },
          ])
        }
        reportFileName={getExportReportName('ScheduledCustomerJobs')}
      />
    );
  };

  const onMultiSelect = (name: string, newValues: iLabelValuePair[]) => {
    if (!name) return;

    switch (name) {
      case 'selectedCustomerIds': {
        setSelectedCustomerIds(newValues);
        break;
      }
      default: {
        break;
      }
    }
  };

  const getSearchPanel = () => {
    return (
      <Grid layout={'fluid'} spacing={'cosy'}>
        <GridColumn medium={8}>
          <Label label={'Customer'} />
          <div className={'customer'}>
            <MultiSelect
              defaultValue={selectedCustomerIds}
              name={'selectedCustomerIds'}
              onConfirm={onMultiSelect}
              placeholder={'Search Customers...'}
              promiseFn={ContactCompanyService.getCustomers}
              optionLabel={'name'}
              sortBy={'name'}
            />
          </div>
        </GridColumn>
        <GridColumn medium={2}>
          <FlexContainer style={{ marginTop: '45px' }}>
            <ResetBtn onClick={onReset} />
            {getExportBtn()}
          </FlexContainer>
        </GridColumn>
      </Grid>
    );
  };

  const getPageHeader = () => {
    return (
      <>
        <FlexContainer className={'space-between'}>
          <FlexContainer>
            <PageTitle>
              Scheduled Customer Jobs{' '}
              <small style={{ fontSize: '12px' }}>This report will ONLY report all OPEN jobs</small>
            </PageTitle>
          </FlexContainer>
        </FlexContainer>
        {getSearchPanel()}
      </>
    );
  };

  const getBody = () => {
    if (isLoading) {
      return <Spinner />;
    }
    if (selectedCustomerIds.length === 0) {
      return null;
    }
    return (
      <Jobs
        getHeader={exportBtn => <div>fdsds{exportBtn}</div>}
        canDelete={false}
        isIndependent
        config={getFilterConf()}
        columns={columns}
      />
    );
  };

  return <BackendPage pageHeader={getPageHeader()}>{getBody()}</BackendPage>;
};

export default ScheduledCustomerJobs;
